import { DARKMODE_COOKIE_NAME, USER_COOKIE_NAME } from "../common/Constants";

function setUser(val) {
  if (val) {
    _paq.push(['setUserId', val.mail]);

    _paq.push(['trackPageView']);

    setCookie(USER_COOKIE_NAME, JSON.stringify(val));
  } else {
    _paq.push(['resetUserId']);

    _paq.push(['trackPageView']);

    setCookie(USER_COOKIE_NAME, null);
  }
}

function getUser() {
  const val = getCookie(USER_COOKIE_NAME);

  if (val) {
    return JSON.parse(val);
  } else {
    return null;
  }
}

function setDarkMode(val) {
  _paq.push(['setCustomVariable', // Index, the number from 1 to 5 where this custom variable name is stored
  1, // Name, the name of the variable, for example: Gender, VisitorType
  "DarkMode", // Value, for example: "Male", "Female" or "new", "engaged", "customer"
  val ? 'on' : 'off', // Scope of the custom variable, "visit" means the custom variable applies to the current visit
  "visit"]);

  _paq.push(['trackPageView']);

  setCookie(DARKMODE_COOKIE_NAME, val);
}

function getDarkMode() {
  return getCookie(DARKMODE_COOKIE_NAME);
}

function setCookie(name, val) {
  if (val !== undefined && val !== null) {
    window.localStorage.setItem(name, val);
  } else {
    window.localStorage.setItem(name, "");
  }
}

function getCookie(name) {
  return window.localStorage.getItem(name);
}

export { setDarkMode, getDarkMode, setUser, getUser };
void function register() {
  /* react-hot-loader/webpack */
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }
  /* eslint-disable camelcase, no-undef */


  var webpackExports = typeof __webpack_exports__ !== 'undefined' ? __webpack_exports__ : exports;
  /* eslint-enable camelcase, no-undef */

  if (!webpackExports) {
    return;
  }

  if (typeof webpackExports === 'function') {
    reactHotLoader.register(webpackExports, 'module.exports', "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/controller/LocalStorageController.js");
    return;
  }
  /* eslint-disable no-restricted-syntax */


  for (var key in webpackExports) {
    /* eslint-enable no-restricted-syntax */
    if (!Object.prototype.hasOwnProperty.call(webpackExports, key)) {
      continue;
    }

    var namedExport = void 0;

    try {
      namedExport = webpackExports[key];
    } catch (err) {
      continue;
    }

    reactHotLoader.register(namedExport, key, "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/controller/LocalStorageController.js");
  }
}();