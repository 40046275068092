import { Titles } from "../common/Language";
import { API } from "../common/Api";
import PpIcon from "../public_assets/pp_logo.png";

function getStandardizedCardData(id, name, showName, tags, children, imageUrl, isPremium, isFavorizable, canDelete, onClick) {
  return {
    id,
    name,
    showName,
    tags,
    children,
    imageUrl,
    isPremium,
    isFavorizable,
    canDelete,
    onClick
  };
}

function transformIconsData(cards, onclicks, isDetailView) {
  if (!cards) {
    return [];
  }

  if (isDetailView) {
    return cards.map(card => {
      return getStandardizedCardData(card.id, card.name, true, card.tags, [], card.path, card.isPremium, false, false, () => onclicks.loadIcon(card));
    });
  } else {
    return cards.map(iconStyle => {
      return getStandardizedCardData(iconStyle.id, iconStyle.name, false, [], [], iconStyle.thumbnail, false, false, false, () => onclicks.clickIconGroup(iconStyle));
    });
  }
}

function transformFavoritesData(cards, onclicks) {
  return cards.map(card => {
    return getStandardizedCardData(card._id, "Slide " + (card.slideNumber + 1), false, card.tags.map(tag => tag.value), [], API.ExpertSlides.assetUrl(card.previewUrl), card.isPremium, true, false, () => onclicks.loadSlide(card));
  });
}

function transformUserStorageData(cards, onclicks) {
  return cards.map(card => {
    let url = API.ExpertSlides.assetUrl(card.url);

    let onClick = () => onclicks.loadUserImg(card);

    if (card.mimetype && card.mimetype.includes("presentation")) {
      url = PpIcon;

      onClick = () => onclicks.loadUserSlide(card);
    }

    return getStandardizedCardData(card._id, card.name, true, card.name, [], url, false, false, true, onClick);
  }).reverse();
}

function transformGifsData(cards, onclicks) {
  if (!(cards && cards.data)) {
    return [];
  }

  const gifs = cards.data;

  if (!(gifs && gifs.length)) {
    return [];
  }

  return gifs.map(gif => {
    return getStandardizedCardData(gif.id, gif.title, true, null, [], gif.images.downsized.url, false, false, false, () => onclicks.loadGif(gif));
  });
}

function transformImagesData(cards, onclicks) {
  if (!(cards && cards.photos && cards.photos.length)) {
    return [];
  }

  return cards.photos.map(card => {
    return getStandardizedCardData(card.id, card.alt, {
      url: card.url,
      photographer: card.photographer
    }, [], [], card.src.large, false, false, false, () => onclicks.loadImg(card));
  });
}

function transformSlides(cards, onclicks, isDetailView) {
  if (!(cards && cards.length)) {
    return [];
  }

  if (isDetailView) {
    const clonedCards = [...cards];
    clonedCards.shift();
    return clonedCards.map(card => {
      return getStandardizedCardData(card._id, "Slide " + (card.slideNumber + 1), false, card.tags.map(element => element.value), [], API.ExpertSlides.assetUrl(card.previewUrl), card.isPremium, true, false, () => onclicks.loadSlide(card));
    });
  } else {
    return cards.map(card => {
      const clonedSlides = [...card.slides];
      clonedSlides.shift();
      return getStandardizedCardData(card._id, card.name, false, [], clonedSlides.map(slide => getStandardizedCardData(slide._id, "Slide " + (slide.slideNumber + 1), false, slide.tags.map(tag => tag.value), [], API.ExpertSlides.assetUrl(slide.previewUrl), slide.isPremium, true, false, () => onclicks.loadSlide(slide))), API.ExpertSlides.assetUrl(card.preview.previewUrl), card.preview.isPremium, false, false, () => onclicks.clickSlideGroup(card.slides, card.name));
    });
  }
}

const CardController = {
  async getCardData(menu) {
    const result = {
      url: "",
      method: "GET",
      body: null,
      authorization: null
    };

    switch (menu) {
      case Titles.Icons:
        return API.Icons.styles();

      case Titles.Favorites:
        return API.ExpertSlides.getFavorites();

      case Titles.UserStorage:
        return API.ExpertSlides.userItems();

      case Titles.Slides:
        return API.ExpertSlides.getItems(false);

      case Titles.Elements:
        return API.ExpertSlides.getItems(true);

      case Titles.Images:
        return API.Images.search();

      case Titles.Gifs:
        return API.Gifs.search();
    }

    return result;
  },

  async transformCardData(menu, cards, onclicks, isDetailView) {
    switch (menu) {
      case Titles.Icons:
        return transformIconsData(cards, onclicks, isDetailView);

      case Titles.Favorites:
        return transformFavoritesData(cards, onclicks);

      case Titles.UserStorage:
        return transformUserStorageData(cards, onclicks);

      case Titles.Gifs:
        return transformGifsData(cards, onclicks);

      case Titles.Images:
        return transformImagesData(cards, onclicks);

      case Titles.Slides:
      case Titles.Elements:
        return transformSlides(cards, onclicks, isDetailView);
    }

    return [];
  }

};
export default CardController;
void function register() {
  /* react-hot-loader/webpack */
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }
  /* eslint-disable camelcase, no-undef */


  var webpackExports = typeof __webpack_exports__ !== 'undefined' ? __webpack_exports__ : exports;
  /* eslint-enable camelcase, no-undef */

  if (!webpackExports) {
    return;
  }

  if (typeof webpackExports === 'function') {
    reactHotLoader.register(webpackExports, 'module.exports', "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/controller/CardController.js");
    return;
  }
  /* eslint-disable no-restricted-syntax */


  for (var key in webpackExports) {
    /* eslint-enable no-restricted-syntax */
    if (!Object.prototype.hasOwnProperty.call(webpackExports, key)) {
      continue;
    }

    var namedExport = void 0;

    try {
      namedExport = webpackExports[key];
    } catch (err) {
      continue;
    }

    reactHotLoader.register(namedExport, key, "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/controller/CardController.js");
  }
}();