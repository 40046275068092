function buildXHR(url, method, data, responseType, authToken) {
  const xhr = new XMLHttpRequest();
  xhr.open(method, url, true);

  if (!(data instanceof FormData)) {
    xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
  }

  xhr.setRequestHeader('Accept', 'application/json');

  if (authToken) {
    if (typeof authToken !== 'object') {
      authToken = {
        type: 'bearer',
        value: authToken
      };
    }

    switch (authToken.type) {
      case 'basic':
        xhr.setRequestHeader('Authorization', authToken.value);
        break;

      default:
        xhr.setRequestHeader('Authorization', 'Bearer ' + authToken.value);
    }
  }

  if (responseType) {
    xhr.responseType = responseType;
  }

  return xhr;
}

function sendXHR(xhr, data) {
  if (data) {
    if (data instanceof FormData) {
      xhr.send(data);
    } else {
      xhr.send(JSON.stringify(data));
    }
  } else {
    xhr.send();
  }
}

export function callApi(url, method, data, responseType, authToken) {
  return new Promise((resolve, reject) => {
    const xhr = buildXHR(url, method, data, responseType, authToken);

    xhr.onloadend = function () {
      if (this.status >= 200 && this.status < 300) {
        if (responseType) {
          resolve(xhr.response);
        } else {
          resolve(JSON.parse(xhr.response));
        }
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      }
    };

    xhr.onerror = function () {
      reject({
        status: xhr.status,
        statusText: xhr.statusText
      });
    };

    try {
      sendXHR(xhr, data);
    } catch (err) {
      console.log(err);
      reject({
        status: 900,
        statusText: err
      });
    }
  });
}
void function register() {
  /* react-hot-loader/webpack */
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }
  /* eslint-disable camelcase, no-undef */


  var webpackExports = typeof __webpack_exports__ !== 'undefined' ? __webpack_exports__ : exports;
  /* eslint-enable camelcase, no-undef */

  if (!webpackExports) {
    return;
  }

  if (typeof webpackExports === 'function') {
    reactHotLoader.register(webpackExports, 'module.exports', "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/common/Api/Request.js");
    return;
  }
  /* eslint-disable no-restricted-syntax */


  for (var key in webpackExports) {
    /* eslint-enable no-restricted-syntax */
    if (!Object.prototype.hasOwnProperty.call(webpackExports, key)) {
      continue;
    }

    var namedExport = void 0;

    try {
      namedExport = webpackExports[key];
    } catch (err) {
      continue;
    }

    reactHotLoader.register(namedExport, key, "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/common/Api/Request.js");
  }
}();