import { callApi } from './Request';
import { getUser } from '../../controller/LocalStorageController';
import { v4 as uuidV4 } from 'uuid';
let ENDPOINTS = null;
let ApiUrl = null;

async function getEndpoints() {
  if (ENDPOINTS === null) {
    const config = await (await import('../Config.js')).default;
    ApiUrl = config.ApiUrl;
    ENDPOINTS = Object.freeze({
      ShopLogin: ApiUrl + 'customers/login',
      ShopLogout: ApiUrl + 'customers/logout',
      ShopValidate: ApiUrl + 'customers/auth',
      ShopHeartbeat: ApiUrl + 'customers/heartbeat',
      LoadItems: ApiUrl + 'items',
      LoadItem: ApiUrl + 'items/',
      UploadUserItem: ApiUrl + 'userstorage/upload',
      LoadUserStorage: ApiUrl + 'userstorage',
      LoadUserStorageSingle: ApiUrl + 'userstorage/',
      SetFavorite: ApiUrl + 'favorites/set',
      GetFavorite: ApiUrl + 'favorites/',
      GetFavorites: ApiUrl + 'favorites',
      GeneratePresentation: ApiUrl + 'ai/generate-presentation',
      GenerateSlide: ApiUrl + 'ai/generate-slide'
    });
  }

  return ENDPOINTS;
}

function getDeviceId() {
  let deviceId = localStorage.getItem('deviceId');

  if (!deviceId) {
    deviceId = uuidV4();
    localStorage.setItem('deviceId', deviceId);
  }

  return deviceId;
}

const ExpertSlides = {
  assetUrl(asset) {
    if (asset.startsWith('http')) {
      return asset;
    } else if (ApiUrl.endsWith('/') && asset.startsWith('/')) {
      return ApiUrl.substring(0, ApiUrl.length - 1) + asset;
    } else {
      return ApiUrl + asset;
    }
  },

  async login(username, password) {
    const Endpoints = await getEndpoints();
    return callApi(Endpoints.ShopLogin, 'POST', {
      username,
      password,
      deviceId: getDeviceId()
    });
  },

  async logout() {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    const Endpoints = await getEndpoints();
    return callApi(Endpoints.ShopLogout, 'POST', null, null, user.token);
  },

  async validate(username, token) {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    const Endpoints = await getEndpoints();
    return callApi(Endpoints.ShopValidate, 'POST', {
      username,
      token
    }, null, token);
  },

  async heartbeat(token) {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    const Endpoints = await getEndpoints();
    return callApi(Endpoints.ShopHeartbeat, 'POST', {
      username: token
    }, null, token);
  },

  async getItems(elements) {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    const Endpoints = await getEndpoints();
    return callApi(Endpoints.LoadItems, 'POST', {
      elements
    }, null, user.token);
  },

  async getItem(id) {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    const Endpoints = await getEndpoints();

    _paq.push(['trackLink', Endpoints.LoadItem + id, 'download']);

    return callApi(Endpoints.LoadItem + id, 'GET', null, null, user.token);
  },

  async upload(data) {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    const Endpoints = await getEndpoints();
    return callApi(Endpoints.UploadUserItem, 'POST', data, null, user.token);
  },

  async userItems() {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    const Endpoints = await getEndpoints();
    return callApi(Endpoints.LoadUserStorage, 'POST', {
      user: user.id
    }, null, user.token);
  },

  async getUserItem(id) {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    const Endpoints = await getEndpoints();
    const url = Endpoints.LoadUserStorageSingle + id;
    return callApi(url, 'POST', {
      user: user.id
    }, null, user.token);
  },

  async getUserSlide(slide) {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    _paq.push(['trackLink', slide.url, 'download']);

    return callApi(ExpertSlides.assetUrl(slide.url), 'GET', null, 'arraybuffer', user.token);
  },

  async getUserImage(image) {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    _paq.push(['trackLink', image.url, 'download']);

    return callApi(ExpertSlides.assetUrl(image.url), 'GET', null, 'arraybuffer', user.token);
  },

  async deleteUserItem(id) {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    const Endpoints = await getEndpoints();
    const url = Endpoints.LoadUserStorageSingle + id + '/delete';
    return callApi(url, 'POST', {
      user: user.id
    }, null, user.token);
  },

  async setFavorite(data) {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    const Endpoints = await getEndpoints();
    return callApi(Endpoints.SetFavorite, 'POST', data, null, user.token);
  },

  async getFavorite(id) {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    const Endpoints = await getEndpoints();
    return callApi(Endpoints.GetFavorite + id, 'POST', {
      user: user.id
    }, null, user.token);
  },

  async getFavorites() {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    const Endpoints = await getEndpoints();
    return callApi(Endpoints.GetFavorites, 'POST', {
      user: user.id
    }, null, user.token);
  },

  async generatePresentation(topic, file, context, language) {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    const Endpoints = await getEndpoints();

    _paq.push(['trackLink', Endpoints.GeneratePresentation, 'ai']);

    const formData = new FormData();
    formData.append('topic', topic);
    formData.append('file', file);
    formData.append('context', context);
    formData.append('language', language);
    return callApi(Endpoints.GeneratePresentation, 'POST', formData, 'arraybuffer', user.token);
  },

  async generateSlide(topic, file, context, language) {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    const Endpoints = await getEndpoints();

    _paq.push(['trackLink', Endpoints.GenerateSlide, 'ai']);

    const formData = new FormData();
    formData.append('topic', topic);
    formData.append('file', file);
    formData.append('context', context);
    formData.append('language', language);
    return callApi(Endpoints.GenerateSlide, 'POST', formData, 'arraybuffer', user.token);
  }

};
export default ExpertSlides;
void function register() {
  /* react-hot-loader/webpack */
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }
  /* eslint-disable camelcase, no-undef */


  var webpackExports = typeof __webpack_exports__ !== 'undefined' ? __webpack_exports__ : exports;
  /* eslint-enable camelcase, no-undef */

  if (!webpackExports) {
    return;
  }

  if (typeof webpackExports === 'function') {
    reactHotLoader.register(webpackExports, 'module.exports', "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/common/Api/ExpertSlides.js");
    return;
  }
  /* eslint-disable no-restricted-syntax */


  for (var key in webpackExports) {
    /* eslint-enable no-restricted-syntax */
    if (!Object.prototype.hasOwnProperty.call(webpackExports, key)) {
      continue;
    }

    var namedExport = void 0;

    try {
      namedExport = webpackExports[key];
    } catch (err) {
      continue;
    }

    reactHotLoader.register(namedExport, key, "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/common/Api/ExpertSlides.js");
  }
}();