const Language = Object.freeze({
  German: 1,
  English: 2
});
let currentLanguage = Language.English;
const Titles = Object.freeze({
  Slides: 1,
  Elements: 2,
  Graphics: 3,
  Icons: 4,
  Images: 5,
  Gifs: 6,
  Colors: 7,
  Favorites: 8,
  UserStorage: 9,
  Settings: 10,
  AI: 11
});

function getTitles() {
  const titles = {
    Slides: 'Slides',
    Elements: 'Elements',
    Graphics: 'Illustrations',
    Icons: 'Icons',
    Images: 'Images',
    Gifs: 'GIFs',
    Colors: 'Color settings',
    Favorites: 'Favorites',
    UserStorage: 'Your Cloud',
    Settings: 'Settings',
    AI: 'ExpertSlides AI'
  };

  if (currentLanguage === Language.German) {
    titles.Slides = 'Folien';
    titles.Elements = 'Elemente';
    titles.Graphics = 'Illustrationen';
    titles.Icons = 'Icons';
    titles.Images = 'Bilder';
    titles.Gifs = 'GIFs';
    titles.Colors = 'Farbauswahl festlegen';
    titles.Favorites = 'Favoriten';
    titles.UserStorage = 'Deine Cloud';
    titles.Settings = 'Einstellungen';
  }

  return titles;
}

function setLanguage(language) {
  if (language === Language.German) {
    currentLanguage = Language.German;
  } else {
    currentLanguage = Language.English;
  }
}

function getLanguages() {
  let languageArray = [];
  Object.keys(Language).forEach(key => {
    languageArray.push({
      id: Language[key],
      name: key
    });
  });
  return languageArray;
}

function getStrings() {
  const strings = {
    actions: {
      close: 'Close',
      keepSourceFormatting: 'Use Template Colors',
      useDestinationTheme: 'Use Custom Colors'
    },
    settings: {
      toggleDarkMode: 'Toggle Dark Mode',
      suggestASlide: 'Suggest slides',
      tutorials: 'Tutorials',
      termsAndConditions: 'Legal Information',
      changeLanguage: 'Change Language...',
      logOut: 'Log out'
    },
    content: {
      filterPlaceholder: 'Search...',
      uploadMore: 'Upload more',
      photographer: 'Photo by {photographer} on Pexels',
      pexels: 'Photos provided by Pexels',
      roundicons: ' ',
      keepSourceFormatting: 'Select <b>\'Use Template Colors\'</b> to use the default colors of the ExpertSlides templates.',
      useDestinationTheme: 'Select <b>\'Use Custom Colors\'</b> to adapt the colors to the current presentation.'
    },
    alerts: {
      titles: {
        error: 'Error',
        heartbeatLogout: 'Duplicate Login'
      },
      messages: {
        noContentFound: 'Nothing found...',
        noSearchResults: 'Nothing found...',
        logInError: 'Could not log you in. Please check your password or username. Take note that you can only log in once with each license. Please try again.',
        tooManyFavorites: 'With your current subscription, you can only add up to five favorites. Please upgrade for unlimited favorites.',
        cloudStorageFull: 'Your cloud storage is full. Please delete some items or upgrade to the pro version to upload more.',
        cloudStorageFullPro: 'Your cloud storage is full. Please delete some items to upload more.',
        fileTypeNotSuported: 'The file type you selected is not supported. Please upload either images or PowerPoint presentations.',
        heartbeatLogout: 'You have been logged out, because your account was used on another device.'
      }
    },
    logIn: {
      username: 'Email',
      password: 'Password',
      signUp: 'Sign up for free',
      logIn: 'Log in',
      forgotPassword: 'Forgot your password?',
      loading: 'Logging you in...',
      valueProposition: 'ExpertSlides helps you create amazing presentations with handcrafted presentation designs. We also offer selected images, GIFs and much more!'
    }
  };

  if (currentLanguage === Language.German) {
    strings.settings.toggleDarkMode = 'Helle/Dunkle UI';
    strings.settings.suggestASlide = 'Folie vorschlagen';
    strings.settings.termsAndConditions = 'Rechtliches';
    strings.settings.changeLanguage = 'Sprache ändern...';
    strings.settings.logOut = 'Ausloggen';
    strings.alerts.titles.error = 'Fehler';
    strings.alerts.titles.heartbeatLogout = 'Doppelter Login';
    strings.actions.close = 'OK';
    strings.actions.keepSourceFormatting = 'Vorlagen Farben benutzen';
    strings.actions.useDestinationTheme = 'Eigene Farben benutzen';
    strings.alerts.messages.logInError = 'Anmeldung fehlgeschlagen. Bitte überprüfe dein Passwort und deinen Nutzernamen. Bitte beachte, dass du dich pro Lizenz nur einmal anmelden kannst und versuche es erneut.';
    strings.alerts.messages.tooManyFavorites = 'Du kannst mit deiner aktuellen Subscription nur maximal 5 Favoriten markieren. Kaufe Premium für unbegrenzte Favoriten.';
    strings.alerts.messages.cloudStorageFull = 'Dein Cloud-Speicher ist voll. Bitte lösche einige Elemente oder kaufe die Pro-Version, um neue Dateien hochladen zu können.';
    strings.alerts.messages.cloudStorageFullPro = 'Dein Cloud-Speicher ist voll. Bitte lösche einige Elemente, um neue hochladen zu können.';
    strings.alerts.messages.noContentFound = 'Keine Elemente...';
    strings.alerts.messages.noSearchResults = 'Nichts passendes gefunden...';
    strings.alerts.messages.fileTypeNotSuported = 'Der ausgewählte Dateityp wird nicht unterstützt. Bitte lade ein Bild oder eine PowerPoint-Datei hoch.';
    strings.alerts.messages.heartbeatLogout = 'Sie wurden abgemeldet, weil Ihr Konto auf einem anderen Gerät verwendet wurde. ';
    strings.content.filterPlaceholder = 'Suchen...';
    strings.content.uploadMore = 'Datei hochladen';
    strings.content.photographer = 'Foto von {photographer} bei Pexels';
    strings.content.pexels = 'Von Pexels zur Verfügung gestellte Fotos';
    strings.content.roundicons = ' ';
    strings.content.keepSourceFormatting = 'Verwende <b>\'Vorlagen Farben benutzen\'</b>, um die vorgegebenen Farben der ExpertSlides Vorlagen zu nutzen.';
    strings.content.useDestinationTheme = 'Verwende <b>\'Eigene Farben benutzen\'</b>, damit sich die Farben der aktuellen Präsentation anpassen.';
    strings.logIn.logIn = 'Einloggen';
    strings.logIn.password = 'Passwort';
    strings.logIn.signUp = 'Kostenlos registrieren';
    strings.logIn.forgotPassword = 'Passwort vergessen?';
    strings.logIn.username = 'E-Mail';
    strings.logIn.loading = 'Einloggen...';
    strings.logIn.valueProposition = 'ExpertSlides hilft Ihnen dabei, fantastische Präsentationen mit unseren handgemachten Präsentationsdesigns zu erstellen. Wir bieten ebenfalls ausgewählte Bilder, GIFs und vieles mehr!';
  }

  return strings;
}

function getTitle(title) {
  const titles = getTitles();

  switch (title) {
    case Titles.Slides:
      return titles.Slides;

    case Titles.UserStorage:
      return titles.UserStorage;

    case Titles.Colors:
      return titles.Colors;

    case Titles.Elements:
      return titles.Elements;

    case Titles.Favorites:
      return titles.Favorites;

    case Titles.Gifs:
      return titles.Gifs;

    case Titles.Graphics:
      return titles.Graphics;

    case Titles.Icons:
      return titles.Icons;

    case Titles.Images:
      return titles.Images;

    case Titles.Settings:
      return titles.Settings;

    case Titles.AI:
      return titles.AI;
  }
}

export { Language, Titles, getTitle, getStrings, setLanguage, getLanguages };
void function register() {
  /* react-hot-loader/webpack */
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }
  /* eslint-disable camelcase, no-undef */


  var webpackExports = typeof __webpack_exports__ !== 'undefined' ? __webpack_exports__ : exports;
  /* eslint-enable camelcase, no-undef */

  if (!webpackExports) {
    return;
  }

  if (typeof webpackExports === 'function') {
    reactHotLoader.register(webpackExports, 'module.exports', "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/common/Language.js");
    return;
  }
  /* eslint-disable no-restricted-syntax */


  for (var key in webpackExports) {
    /* eslint-enable no-restricted-syntax */
    if (!Object.prototype.hasOwnProperty.call(webpackExports, key)) {
      continue;
    }

    var namedExport = void 0;

    try {
      namedExport = webpackExports[key];
    } catch (err) {
      continue;
    }

    reactHotLoader.register(namedExport, key, "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/common/Language.js");
  }
}();