'use strict';

import { DebugIntegration } from './Debug.js';
import { saveAs } from 'file-saver';
export class WebIntegration extends DebugIntegration {
  async addSlide(slide) {
    if (typeof slide === 'string') {
      saveAs(new Blob([atob(slide)]), 'Slide.pptx');
    } else {
      saveAs(new Blob([slide]), 'Slide.pptx');
    }
  }

  async addBitmap(image, filename = 'file.data') {
    saveAs(new Blob([image]), filename);
  }

  async addVector(svg, width = 150) {
    saveAs(new Blob([svg]), 'image.svg');
  }

}
void function register() {
  /* react-hot-loader/webpack */
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }
  /* eslint-disable camelcase, no-undef */


  var webpackExports = typeof __webpack_exports__ !== 'undefined' ? __webpack_exports__ : exports;
  /* eslint-enable camelcase, no-undef */

  if (!webpackExports) {
    return;
  }

  if (typeof webpackExports === 'function') {
    reactHotLoader.register(webpackExports, 'module.exports', "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/common/Integrations/Web.js");
    return;
  }
  /* eslint-disable no-restricted-syntax */


  for (var key in webpackExports) {
    /* eslint-enable no-restricted-syntax */
    if (!Object.prototype.hasOwnProperty.call(webpackExports, key)) {
      continue;
    }

    var namedExport = void 0;

    try {
      namedExport = webpackExports[key];
    } catch (err) {
      continue;
    }

    reactHotLoader.register(namedExport, key, "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/common/Integrations/Web.js");
  }
}();