import { getUser } from '../../controller/LocalStorageController';
import { callApi } from './Request';
const IMAGES_PER_PAGE = 15;
const Authorization = Object.freeze({
  Gifs: 'SEFrroddAJ1DEPoLziInzfwQLgBzRad7'
});
const Endpoints = Object.freeze({
  LoadGifs: `https://api.giphy.com/v1/gifs/trending`,
  SearchGifs: `https://api.giphy.com/v1/gifs/search`
});
export default {
  async search({
    query = null,
    page = null
  } = {}) {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    let url = Endpoints.LoadGifs;
    const q = new URLSearchParams();
    q.set('api_key', Authorization.Gifs);
    q.set('limit', '' + IMAGES_PER_PAGE);

    if (query) {
      url = Endpoints.SearchGifs;
      q.set('q', query);
    }

    if (typeof page === 'number') {
      q.set('offset', (page * IMAGES_PER_PAGE).toString());
    }

    url += '?' + q.toString();
    return callApi(url, 'GET');
  },

  async download(gif) {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    _paq.push(['trackLink', gif.images.original.url, 'download']);

    return callApi(gif.images.original.url, 'GET', null, 'arraybuffer');
  }

};
void function register() {
  /* react-hot-loader/webpack */
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }
  /* eslint-disable camelcase, no-undef */


  var webpackExports = typeof __webpack_exports__ !== 'undefined' ? __webpack_exports__ : exports;
  /* eslint-enable camelcase, no-undef */

  if (!webpackExports) {
    return;
  }

  if (typeof webpackExports === 'function') {
    reactHotLoader.register(webpackExports, 'module.exports', "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/common/Api/Giphy.js");
    return;
  }
  /* eslint-disable no-restricted-syntax */


  for (var key in webpackExports) {
    /* eslint-enable no-restricted-syntax */
    if (!Object.prototype.hasOwnProperty.call(webpackExports, key)) {
      continue;
    }

    var namedExport = void 0;

    try {
      namedExport = webpackExports[key];
    } catch (err) {
      continue;
    }

    reactHotLoader.register(namedExport, key, "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/common/Api/Giphy.js");
  }
}();