const KEEP_SOURCE_FORMATTING = "KeepSourceFormatting";
const USE_DESTINATION_THEME = "UseDestinationTheme";
const DARKMODE_COOKIE_NAME = "EXPERTSLIDES-DARKMODE";
const USER_COOKIE_NAME = "EXPERTSLIDES_USER";
export { KEEP_SOURCE_FORMATTING, USE_DESTINATION_THEME, DARKMODE_COOKIE_NAME, USER_COOKIE_NAME };
void function register() {
  /* react-hot-loader/webpack */
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }
  /* eslint-disable camelcase, no-undef */


  var webpackExports = typeof __webpack_exports__ !== 'undefined' ? __webpack_exports__ : exports;
  /* eslint-enable camelcase, no-undef */

  if (!webpackExports) {
    return;
  }

  if (typeof webpackExports === 'function') {
    reactHotLoader.register(webpackExports, 'module.exports', "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/common/Constants.js");
    return;
  }
  /* eslint-disable no-restricted-syntax */


  for (var key in webpackExports) {
    /* eslint-enable no-restricted-syntax */
    if (!Object.prototype.hasOwnProperty.call(webpackExports, key)) {
      continue;
    }

    var namedExport = void 0;

    try {
      namedExport = webpackExports[key];
    } catch (err) {
      continue;
    }

    reactHotLoader.register(namedExport, key, "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/common/Constants.js");
  }
}();