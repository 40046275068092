import { getUser } from '../../controller/LocalStorageController';
import { callApi } from './Request';
const ICONS_PER_PAGE = 50;
let iconCache = {};
let categoryCache = null;

async function getCategories() {
  if (categoryCache === null) {
    categoryCache = await callApi(`/public/icons/categories.json`, 'GET');
    categoryCache = categoryCache.map(category => ({ ...category,
      thumbnail: `/public/icons/${category.name}.jpeg`,
      index: parseInt(category.name.split('.').shift())
    })).sort((a, b) => a.index - b.index);
  }

  return categoryCache;
}

function iconMatches(icon, query) {
  return icon.tags.some(tag => tag.match(query)) || icon.name.match(query);
}

async function cacheIcons(category) {
  if (!iconCache[category.id]) {
    iconCache[category.id] = await callApi(`/public/icons/${category.id}_icons.json`, 'GET');
    iconCache[category.id] = iconCache[category.id].map(icon => ({ ...icon,
      tags: icon.tags.map(tag => tag.name),
      path: `/public/icons/${icon.path}`,
      name: icon.path.split('/').pop().split('.').slice(0, -1).join('.'),
      isPremium: category.isPremium || category.premium
    }));
  }
}

async function getCachedIcons({
  query = null,
  category = null
}) {
  let icons = [];

  if (category) {
    await cacheIcons(category);
    icons = [...iconCache[category.id]];
  } else {
    const categories = await getCategories();
    await Promise.all(categories.map(category => cacheIcons(category)));
    icons = categories.map(category => [...iconCache[category.id]]).flat();
  }

  console.group();
  console.debug({
    func: 'getCachedIcons',
    query,
    category
  });

  if (query) {
    console.debug('filter by tags');
    const queryRegex = new RegExp(query, 'i');
    icons = icons.filter(icon => iconMatches(icon, queryRegex));
  }

  console.debug('result icons', icons.length);
  console.groupEnd();
  return icons;
}

const Icons = {
  async search({
    query = null,
    page = 1,
    styleId = null,
    limit = ICONS_PER_PAGE
  } = {}) {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    const icons = await getCachedIcons({
      query,
      category: styleId
    });
    console.debug({
      func: 'Icons.search',
      limit,
      page
    }, limit * (page - 1), limit * page);
    return icons.slice(limit * (page - 1), limit * page);
  },

  async styles() {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    return getCategories();
  },

  async download(icon) {
    const user = getUser();

    if (!user) {
      throw new Error('not logged in');
    }

    const url = icon.path;

    _paq.push(['trackLink', url, 'download']);

    return callApi(url, 'GET', null, 'text');
  }

};
export default Icons;
void function register() {
  /* react-hot-loader/webpack */
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }
  /* eslint-disable camelcase, no-undef */


  var webpackExports = typeof __webpack_exports__ !== 'undefined' ? __webpack_exports__ : exports;
  /* eslint-enable camelcase, no-undef */

  if (!webpackExports) {
    return;
  }

  if (typeof webpackExports === 'function') {
    reactHotLoader.register(webpackExports, 'module.exports', "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/common/Api/RoundIcons.js");
    return;
  }
  /* eslint-disable no-restricted-syntax */


  for (var key in webpackExports) {
    /* eslint-enable no-restricted-syntax */
    if (!Object.prototype.hasOwnProperty.call(webpackExports, key)) {
      continue;
    }

    var namedExport = void 0;

    try {
      namedExport = webpackExports[key];
    } catch (err) {
      continue;
    }

    reactHotLoader.register(namedExport, key, "/Users/srey/Projects/Web/Designomo-ExpertSlidesAddin/src/taskpane/common/Api/RoundIcons.js");
  }
}();